<template>
  <div style=";width: 40rem;margin: 1rem;padding: 1rem;border: 1px solid #ededed;border-radius: .5rem">
    <h4 style="color: #909399;margin-bottom: 1.5rem">版本更新记录({{ total }})</h4>
    <el-timeline>
      <el-timeline-item
          v-for="(item,key) in list" :key="key"
          :color="key ===0 ? 'rgb(76, 162, 255)' : ''"
      >
        <h4>{{ item.version }}</h4>
        <div class="content-box">
          <p class="content-item" v-for="(v,k) in item.content" :key="k">{{ v }}</p>
        </div>
      </el-timeline-item>
    </el-timeline>
    <div v-if="!empty" @click="next" style="color: #4ca2ff;text-align: center" class="y-pointer y-desc">加载更多...
    </div>
  </div>
</template>

<script>
export default {
  name: "upgradeLog",
  data() {
    return {
      page: 1,
      size: 0,
      total: 0,
      list: [],
      empty: true,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    pageChange(e) {
      this.page = e;
    },
    next() {
      this.page++;
      this.load();
    },
    load() {
      this.$u.api.founder.upgradeLog({page: this.page}).then(res => {
        if (res.page === 1) {
          this.page = res.page;
          this.size = res.size;
          this.total = res.total;
        }
        this.empty = res.list.length < res.size;
        res.list.forEach(item => {
          this.list.push(item)
        })
      })
    },
  }
}
</script>

<style scoped>
.content-item + .content-item {
  margin-top: .5rem;
  padding-top: .5rem;
  border-top: 1px dashed #ededed;
}

.content-box {
  white-space: pre-wrap;
  color: #909399;
  margin-top: .5rem;
  font-size: .8rem;
  line-height: 1.4rem;
}
.item-box+.item-box{
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 2px solid #909399;
}
</style>